<template>
  <v-container fluid>
    <v-col
      cols="12"
      v-if="loading">
      <div class="text-center" >
        <v-progress-circular
          indeterminate
          color="primary" />
      </div>
    </v-col>
    <form
      @submit.prevent="save(false, null)"
      v-else>
      <v-stepper>
        <v-stepper-header>
          <v-stepper-step
            step="1"
            @click="changeStatus(1)"
            :complete="appointment.status > 0">
            Neu
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            @click="changeStatus(10)"
            :complete="appointment.status > 9">
            Angenommen
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            @click="changeStatus(20)"
            :complete="appointment.status > 19">
            Abgeschlossen
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <ConsultingAppointmentFeedbackDialog
        :open="openFeedbackDialog"
        @success="saveFeedback"
      />
      <v-tabs
        v-model="active"
        class="tab-spacing"
        slider-color="primary">
        <v-tab
          v-for="tab in tabs"
          :key="tab"
          ripple>
          <template v-if="tab !== 'Notizen'">{{ tab }}</template>
          <template v-else>
            <v-badge
              color="grey lighten-1"
              right>
              <template slot="badge">
                <span>{{ appointment.notes.length }}</span>
              </template>
              <span>{{ tab }}</span>
            </v-badge>
          </template>
        </v-tab>
        <v-tab-item key="Allgmein">
          <v-row>
            <v-col cols="4">
              <v-card class="margin-card">
                <v-card-text>
                  <h3>
                    <v-icon class="icon-heading">person</v-icon>
                    Kunde
                  </h3>
                  <div class="details">{{ appointment.name }}</div>
                  <div class="details">{{ appointment.email }}</div>
                  <div class="details">{{ appointment.phone }}</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="margin-card">
                <v-card-text>
                  <h3>
                    <v-icon class="icon-heading">date_range</v-icon>
                    Termin
                  </h3>
                  <div>
                    <AppointmentModal
                      :date="appointment.date"
                      :availabilities="availabilities"
                      @selected="appointment.date = $event"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="margin-card">
                <v-card-text>
                  <h3>
                    <v-icon class="icon-heading">map</v-icon>
                    Filiale
                  </h3>
                  <div class="details">{{ branchOffice.street }}</div>
                  <div class="details">
                    {{ branchOffice.zip }}
                    {{ branchOffice.city }}
                  </div>
                  <BranchOfficeModal
                    @changeBranchOffice="appointment.branch_office_id = $event"
                    :selected="appointment.branch_office_id"
                    :branchOffices="branchOffices"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-card class="mt-2">
            <v-card-text>
              <v-col cols="12">
                <h3 class="mt-0">Produkt</h3>
                {{ productName }}
                <h3>Kommentar</h3>
                {{ appointment.comment }}
              </v-col>
              <v-col cols="12">
                <v-btn
                  :loading="loading"
                  color="success"
                  type="submit">
                  Speichern
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="Notizen">
          <NoteTab
            type="2"
            :foreignId="appointment.id"
            :items="appointment.notes"
            @success="loadAppointment"
          />
        </v-tab-item>
      </v-tabs>
    </form>
  </v-container>
</template>

<script>
import NoteTab from '@/components/NoteTab.vue'
import AppointmentsAPI from '@/api/appointments'
import AppointmentModal from '@/components/AppointmentModal.vue'
import BranchOfficeModal from '@/components/BranchOfficeModal.vue'

export default {
  data() {
    return {
      active: null,
      tabs: ['Allgemein', 'Notizen'],
      statusTypes: [
        { key: 1, value: 'Neu' },
        { key: 10, value: 'Angenommen' },
        { key: 20, value: 'Abgeschlossen' },
      ],
      loading: false,
      appointment: null,
      branchOffices: null,
      availabilities: null,
      openFeedbackDialog: false,
    }
  },
  created() {
    this.loadAppointment()
  },
  computed: {
    productName() {
      if (this.appointment && this.appointment.product) {
        return this.appointment.product.fullname
      }
      return 'Kein Produkt ausgewählt'
    },
    branchOffice() {
      if (this.branchOffices) {
        return this.branchOffices.find((item) => item.id === this.appointment.branch_office_id)
      }
      return null
    },
  },
  methods: {
    loadAppointment() {
      this.loading = true
      AppointmentsAPI.getConsultingAppointment(this.$route.params.id).then((response) => {
        this.appointment = response.appointment
        this.branchOffices = response.branch_offices
        this.availabilities = response.availabilities
        this.$store.commit('app/setTitle', `Kundenberatung vom ${this._f('datetime')(this.appointment.created_at)}`)
        this.loading = false
      })
    },
    save(silentMode, data) {
      silentMode = silentMode || false
      data = data || {}

      if (!silentMode) {
        this.loading = true
      }
      AppointmentsAPI.updateConsultingAppointment(
        this.$route.params.id,
        this.appointment.status,
        this.appointment.date,
        data.product_model,
        data.note,
        this.appointment.branch_office_id,
      ).then(() => {
        this.loading = false
        if (!silentMode) {
          this.$toasted.success('Der Beratungstermin wurde erfolgreich aktualisiert.')
          this.$router.push('/appointments/consulting')
        }
      }).catch(() => {
        this.$toasted.error('Der Status des Beratungstermins konnte nicht geändert werden')
      })
    },
    changeStatus(status) {
      if (status === 20 && this.appointment.status !== 20) {
        this.openFeedbackDialog = true
        this.appointment.status = 20
        return
      }
      this.appointment.status = status
      this.save(true, null)
    },
    saveFeedback(data) {
      this.openFeedbackDialog = false
      this.save(true, data)
      this.loadAppointment()
    },
  },
  components: {
    BranchOfficeModal,
    AppointmentModal,
    NoteTab,
  },
}
</script>

<style lang="scss" scoped>
    .v-stepper__step {
        cursor: pointer;
    }

    .tab-spacing {
        margin-top: 30px;
    }

    form {
        width: 100%;
    }

    .margin-card {
        margin-top: 5px;
        height: 100%;

        .icon-heading {
            font-size: 30px;
        }

        .details {
            font-size: 18px;
        }

        h3 {
            margin: 10px 0;
        }
    }

    .container.grid-list-xl .layout {
        margin-left: -5px;
        margin-right: -5px;

        .flex {
            &:first-child {
                padding-right: 0px;
            }
            &:last-child {
                padding-left: 0px;
            }

            &:not(:first-child, :last-child) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
</style>
